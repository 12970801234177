<template>
  <div class="auth">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">实名认证</div>
    <div class="card">
        <div class="step_lines">
            <span class="active">1</span>
            <div :class="[ 'line', step>1 ? 'active': '']"></div>
            <span :class="[ step>1 ? 'active': '']">2</span>
            <div :class="[ 'line', step>2 ? 'active': '']"></div>
            <span :class="[ step>2 ? 'active': '']">3</span>
        </div>
        <div class="step_text">
            <span class="active">企业认证</span>
            <span :class="[ step>1 ? 'active': '']">授权银行卡</span>
            <span :class="[ step>2 ? 'active': '']">认证完成</span>
        </div>
    </div> 
    <form class="auth-card" v-if="step == 1">
        <div class="catalog">
            <p>企业名称</p>
            <input type="text" :value="companyName" disabled>
        </div>
        <div class="catalog">
            <p>统一社会信用代码</p>
            <input type="text" :value="creditCode" disabled>
        </div>
        <div class="catalog">
            <p>法人姓名</p>
            <input type="text" placeholder="输入法人姓名" v-model="legalPersonName" maxlength="50">
        </div>
        <div class="catalog">
            <p>法人身份证</p>
            <input type="text" placeholder="输入法人身份证号码" v-model="idcard" maxlength="18">
        </div>
        <!-- <div class="catalog">
            <p>&nbsp;</p>
            <div class="add_pic">
                <img src="@/assets/img/add_big.png" alt="">
                <p>企业法人营业执照</p>
            </div>
        </div> -->
       <div class="footer-btn">
            <div class="auth-btn" @click="setStep(2)">下一步</div>
        </div>

    </form>
    <form class="auth-card" v-if="step == 2">
        <div class="catalog">
            <p>姓名</p>
            <input type="text" :value="legalPersonName" disabled>
        </div>
        <div class="catalog">
            <p>身份证</p>
            <input type="text" :value="idcard" disabled>
        </div>
        <div class="catalog">
            <p>银行卡号</p>
            <input type="number" placeholder="输入要授权的银行卡号" v-model="bankcard" oninput="if(value.length>50) value=value.slice(0,50)">
        </div>
        <div class="catalog">
            <p>手机号码</p>
            <input type="number" placeholder="输入银行预留手机号" v-model="mobile" oninput="if(value.length>11) value=value.slice(0,11)">
        </div>
       <div class="footer-btn">
            <div class="auth-btn" @click="setStep(1)">上一步</div>
            <div class="auth-btn" @click="submit()">提交认证</div>
        </div>

    </form>
    <div class="auth-card" v-if="step == 3">
        <div class="pass">
            <img src="../../assets/img/tongguo.png" alt="">
            <p class="p1">&nbsp;&nbsp;&nbsp;恭喜您！</p>
            <p class="p2">您的实名认证已通过</p>
        </div>
        <div class="footer-btn">
            <router-link to="/auth" replace class="auth-btn">返回</router-link>
        </div>
    </div>
  </div>
</template>
<script>
import { ref,reactive,toRefs,toRaw } from 'vue';
import {apiGetEnterInfo,apiGxiAuth} from '../../api/axios';
import { Toast } from 'vant';
import { useStore } from 'vuex'
export default {
   setup() {
       const step = ref(1);
       let store = useStore();
        const state = reactive({
            companyName: '',
            creditCode: '',
            reqType: 'C0201',
            idtype: '01',
            legalPersonName: '',
            username: '',
            idcard: '',
            bankcard: '',
            mobile: '',
        })
        const setStep = (val) =>{
            if(!state.legalPersonName) {
                Toast('请先输入法人姓名');
                return
            }
            if(state.idcard.length < 15) {
                Toast('请先输入正确的法人身份证');
                return
            }
            step.value = val;
        };
        apiGetEnterInfo({}).then(res => {                 
             if(res.code == '1'){
                 state.companyName = res.info.enterBaseInfo.enterName;
                 state.creditCode = res.info.enterRegisterInfo.uniscid;
              }
              else {
                Toast(res.msg);
              }
          });
        let lf = store.state.loginInfo;
        const submit = () =>{
            if(!state.bankcard) {
                Toast('请先输入要授权的银行卡号');
                return
            }
            if(!state.mobile) {
                Toast('请先输入银行预留手机号');
                return
            }
            let time = Date.parse(new Date()).toString();
            let obj = toRaw(state);
            obj.username = state.legalPersonName;
            obj.timestap = time;
            obj.bankcard = state.bankcard.toString();
            obj.mobile = state.mobile.toString();
            // console.log(obj)
            Toast.loading({
                duration: 0,
                message: '提交认证中...',
                forbidClick: true,
                loadingType: 'spinner',
            });
             apiGxiAuth(obj).then(res => {
                    Toast.clear();              
                    if(res.code === '1'){
                        // authInfo.value = res.info.info;
                        if(res.info.c_code === '0') {
                            step.value = 3;
                            lf.isOrgAuth = '1';
                            localStorage.setItem("gxrToken", JSON.stringify(lf));
                            store.dispatch('setToken',lf);
                        }else {
                            Toast.fail(res.info.c_msg);
                        }
                        
                    }
                    else {
                        Toast.fail(res.msg);
                    }
            });
            // step.value = 3;
        };
     return {
         step,
         ...toRefs(state),
         setStep,
         submit
     }
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.auth {
    padding-top: 12.5vw;
    // background-color: #fff;
    .step_lines {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 4vw;
        span {
            text-align: center;
            line-height: 6vw;
            width: 6vw;
            height: 6vw;
            border-radius: 50%;
            color: rgb(128, 127, 127);
            background-color: #ccc;
        }
        .line {
            border-top: 1px solid #ccc;
            flex: 1;
        }
        .active {
        color: #fff;
        background-color: #ff9900;
        border-color: #ff9900;
        }
    }
    .step_text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 4vw;
        margin-top: 3px;
        color: #666;
        .active {
            color: #ff9900;
        }
    }
    
    .auth-card {
        margin: 4vw;
        background-color: #fff;
        border-radius: 1.5vw;
        padding: 4vw;
        .catalog {
            display: flex;
            align-items: center;
            
            p:first-child {
                width: 24vw;
                color: #666666;
                font-size: 4vw;
                padding: 3vw 0;
                
                .key {
                    color: #ff6600;
                }
            }
            // p:last-child {
            //     width: 57vw;
            //     font-size: 4vw;
            //     padding-left: 4vw;

            // }
            .add_pic {
                width: 57vw;
                height: 39vw;
                font-size: 4vw;
                // padding-left: 4vw;
                margin-left: 4vw;
                border-radius: 1vw;
                background-color: #f4f8fe;
                text-align: center;
                img {
                    width: 13vw;
                    margin: 6vw 0 2vw;
                }
                p {
                    color: #336699;
                }

            }
            input {
                background-color: #f4f8fe;
                border: 0;
                margin-left: 4vw;
                width: 57vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
            }
            ::-webkit-input-placeholder {
              color: #7f9abb;
            }
            ::-moz-placeholder {
              color: #7f9abb;
            }
            :-ms-input-placeholder {
              color: #7f9abb;
            }
            :-moz-placeholder {
              color: #7f9abb;
            }

        }
        .pass {
            text-align: center;
            margin: 4vw 0;
            img {
                width: 15vw;
            }
            .p1 {
                font-weight: 600;
                font-size: 5.5vw;
            }
            .p2 {
                font-size: 4vw;
            }
        }
        .footer-btn {
            display: flex;
            justify-content: center;
            .auth-btn {
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4vw;
            padding: 1.5vw;
            border-radius: 7vw;
            border: 0;
            margin: 5vw 2vw 0;
            width: 25vw;
            text-align: center;
            letter-spacing: 1px;
        }   
    

        }
        
    }

    
}
</style>
